import React, { FC, useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import { dropLast, equals, update } from "ramda";

import { Button, P } from "common/components/atoms";
import { fields } from "common/components/molecules/StakeholderSection/Stakeholder.types";
import { PlusIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { EditStakeholderDTO, PostStakeholderDTO } from "../types";
import StakeholderFormContactsTable from "./stakeholder-form-contacts-table";
import StakeholderFormSingleContact from "./stakeholder-form-single-contact";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.form.contacts");

type Props = {
  selectedContactIndex?: number;
  setSelectedContactIndex: (val?: number) => void;
  setIsContactsDataChanges?: (val: boolean) => void;
};

const StakeholderFormContacts: FC<Props> = ({
  selectedContactIndex,
  setSelectedContactIndex,
  setIsContactsDataChanges,
}) => {
  const { initialValues, values, setFieldValue } = useFormikContext<
    (PostStakeholderDTO | EditStakeholderDTO) &
      Record<"addContactFormOpen", boolean> &
      Record<"editingContact", boolean>
  >();

  const handleOpenForm = () => {
    const indexToSet = values?.contacts?.length;

    setSelectedContactIndex(indexToSet);
    setFieldValue("addContactFormOpen", true);
    setFieldValue("internalFormSubmitted", true);

    setFieldValue(`contacts.${indexToSet}`, {
      [fields.firstName]: "",
      [fields.lastName]: "",
      [fields.email]: undefined,
      [fields.phoneNumber]: undefined,
      stakeholderContactRoleId: undefined,
    });
  };

  const handleCloseForm = () => {
    if (!values.editingContact) {
      setFieldValue("contacts", dropLast(1, values.contacts));
    } else {
      const contacts = update(
        Number(selectedContactIndex),
        initialValues.contacts[Number(selectedContactIndex)],
        values.contacts
      );

      setFieldValue("contacts", contacts);
    }

    setFieldValue("addContactFormOpen", false);
    setFieldValue("editingContact", false);
    setFieldValue("internalFormSubmitted", false);
    setSelectedContactIndex(undefined);
  };

  const handleEditClick = (val: number) => {
    setSelectedContactIndex(val);
    setFieldValue("editingContact", true);
    setFieldValue("addContactFormOpen", true);
    setFieldValue("internalFormSubmitted", true);
  };

  const handleAddContact = useCallback(() => {
    setSelectedContactIndex(undefined);
    setFieldValue("editingContact", false);
    setFieldValue("addContactFormOpen", false);
    setFieldValue("internalFormSubmitted", false);
  }, [setFieldValue, setSelectedContactIndex]);

  useEffect(() => {
    const isDataChanged = JSON.stringify(initialValues.contacts) !== JSON.stringify(values.contacts);

    setIsContactsDataChanges?.(isDataChanged);
  }, [initialValues, setIsContactsDataChanges, values]);

  return (
    <div>
      {values.addContactFormOpen ? (
        <StakeholderFormSingleContact
          addContact={handleAddContact}
          handleClose={handleCloseForm}
          selectedContactIndex={selectedContactIndex}
        />
      ) : (
        <>
          <P.m>{t("description")}</P.m>

          {values.contacts.length ? <StakeholderFormContactsTable handleEditClick={handleEditClick} /> : null}

          <Button size="s" variant="secondary" className="mt-3" iconLeft={<PlusIcon />} onClick={handleOpenForm}>
            {t("addContact")}
          </Button>
        </>
      )}
    </div>
  );
};

export default StakeholderFormContacts;
